import {
  type CurrentSportYearRecord,
  type MenuResponse,
  rdbV1DefaultYearList,
  rdbV1FiltersSportsList,
  type SiteResponse,
  type SiteSubConfigRes,
  type SlimSiteResponse,
  type SportFilter,
  v1SitesDetail,
  v1SitesSlimSitesList,
  v1SitesSubConfigsDetail,
} from '@on3/api';
import { getFulfilledValue, proxyApi } from '@on3/ui-lib/utils/api';
import { getSiteChecks, getSiteMenus } from '@on3/ui-lib/utils/sites';
import { NextComponentType, NextPageContext } from 'next';

import { NextApiParams } from '../types/next';
import { filterRankingSports } from '../utils/filterRankingSports';

const withValidUrl = (
  WrappedComponent: NextComponentType<NextPageContext, unknown, any>,
) =>
  function WithValidUrl(props: any) {
    const currentSite = props?.siteData?.currentSite;

    if (currentSite) {
      return <WrappedComponent {...props} />;
    }

    // Throw error here.
    return null;
  };

interface ISiteObjectResponse {
  currentSite?: SiteResponse;
  siblingSite?: SiteResponse | null;
  siteUrls?: SlimSiteResponse[];
  menuData: MenuResponse[] | null;
  secondaryMenuData: MenuResponse[] | null;
  sportsData?: SportFilter[] | null;
  rankingSports?: SportFilter[];
  defaultYears?: CurrentSportYearRecord | null;
  subConfig?: SiteSubConfigRes | null;
}

withValidUrl.isValid = async (ctx: NextApiParams, siteKey?: number | null) => {
  const siteUrls = await v1SitesSlimSitesList(proxyApi, {});

  // Default to On3
  let slimSite = siteUrls.find((url: SlimSiteResponse) => url.key === 44);

  if (siteKey) {
    slimSite = siteUrls.find((url: SlimSiteResponse) => url.key === siteKey);
  } else {
    if (ctx?.query?.team) {
      slimSite = siteUrls?.find(
        (url: SlimSiteResponse) =>
          url.url?.toLowerCase() ===
          `/teams/${(ctx?.query?.team as string)?.toLowerCase()}`,
      );
    } else if (ctx?.query?.site) {
      slimSite = siteUrls?.find(
        (url: SlimSiteResponse) =>
          url.url?.toLowerCase() ===
          `/sites/${(ctx?.query?.site as string)?.toLowerCase()}`,
      );
    } else if (ctx?.query?.college) {
      slimSite = siteUrls?.find(
        (url: SlimSiteResponse) =>
          url.url?.toLowerCase() ===
          `/college/${(ctx?.query?.college as string).toLowerCase()}`,
      );
    }
  }

  if (!slimSite?.key || !slimSite?.live) {
    console.error({ url: ctx?.req?.url, error: 'Valid Url Check: line 62' });

    return null;
  }

  const [currentSiteResult, siblingSiteResult] = await Promise.allSettled([
    v1SitesDetail(proxyApi, slimSite.key),
    slimSite.siblingSiteKey
      ? v1SitesDetail(proxyApi, slimSite.siblingSiteKey)
      : Promise.resolve(null),
  ]);

  const currentSite = getFulfilledValue(currentSiteResult);
  const siblingSite = getFulfilledValue(siblingSiteResult);

  if (!currentSite) {
    console.error({
      url: ctx?.req?.url,
      error: `Current site failed ${slimSite.key} `,
    });

    return null;
  }

  const siteMenus: (Promise<MenuResponse[]> | null)[] = getSiteMenus(
    currentSite,
    ctx,
  );
  const { key, allowSubs, siblingSiteKey } = currentSite;

  const subConfigKey = allowSubs ? key : siblingSiteKey || 44;
  const [primaryMenu, secondaryMenu, sportsData, defaultYears, subConfig] =
    await Promise.allSettled([
      siteMenus[0],
      siteMenus[1],
      rdbV1FiltersSportsList(proxyApi),
      rdbV1DefaultYearList(proxyApi),
      v1SitesSubConfigsDetail(proxyApi, subConfigKey),
    ]);

  const sportsDataResponse = getFulfilledValue(sportsData);

  const rankingSports = filterRankingSports(sportsDataResponse || []);

  // Cancer
  const siteChecks = getSiteChecks({
    site: currentSite,
    pathname: ctx.pathname ?? currentSite.url ?? '',
    query: ctx.query ?? {},
  });

  let secondaryMenuData = getFulfilledValue(secondaryMenu);

  if (
    siteChecks.isTeam ||
    siteChecks.isPro ||
    siteChecks.isBetting ||
    siteChecks.isOn3
  ) {
    secondaryMenuData = secondaryMenuData?.splice(0, 6) ?? [];
  }
  // End Cancer

  const data: ISiteObjectResponse | null = {
    currentSite,
    siblingSite,
    siteUrls,
    menuData: getFulfilledValue(primaryMenu),
    secondaryMenuData,
    sportsData: sportsDataResponse,
    rankingSports,
    defaultYears: getFulfilledValue(defaultYears),
    subConfig: getFulfilledValue(subConfig),
  };

  return data;
};

export default withValidUrl;
export { withValidUrl };
